import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import { UserDetails } from "./types";
import {
  onDeleteUserQueryStarted,
  onUpdateUserDetailsQueryStarted,
} from "./utils";

export const userDetailsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserDetails: build.query<UserDetails, void>({
      query: () => "users",
      providesTags: ["UserDetails"],
    }),

    updateUserDetails: build.mutation<UserDetails, UserDetails>({
      query: (patch) => ({
        url: `users`,
        method: "PATCH",
        body: transformKeysToSnakeCase(patch),
      }),
      invalidatesTags: ["UserDetails"],
      onQueryStarted: onUpdateUserDetailsQueryStarted,
    }),
    deleteUser: build.mutation<void, void>({
      query: (patch) => ({
        url: `users`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserDetails"],
      onQueryStarted: onDeleteUserQueryStarted,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useDeleteUserMutation,
} = userDetailsApi;
