import { ActivityIndicator, Pressable, StyleSheet, View } from "react-native";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { BaseButtonProps } from "./types";
import { useState } from "react";
import { set } from "react-hook-form";

const DECBaseButton = ({
  children,
  onPress,
  onPressIn,
  onPressOut,
  isLoading,
  isSuccess,
  isError,
  disabled,
  containerStyle,
  containerStyleOnSuccess,
  containerStyleOnError,
}: BaseButtonProps) => {
  // Adding this state value because since upgrading to Expo SDK 51 the style won't show if we pass a function to the style prop of Pressed
  const [isPressed, setIsPressed] = useState(false);

  const handleOnPressIn = () => {
    setIsPressed(true);
    onPressIn && onPressIn();
  };

  const handleOnPressOut = () => {
    setIsPressed(false);
    onPressOut && onPressOut();
  };
  return (
    <Pressable
      onPress={onPress}
      onPressIn={handleOnPressIn}
      onPressOut={handleOnPressOut}
      disabled={isLoading || disabled}
      style={[
        styles.button,
        containerStyle,
        isPressed && styles.pressed,
        isSuccess && { ...styles.success, ...containerStyleOnSuccess },
        isError && { ...styles.error, ...containerStyleOnError },
      ]}
    >
      <View
        style={[
          styles.content,
          isLoading && { position: "relative", left: -15 },
        ]}
      >
        {isLoading && (
          <ActivityIndicator
            size="small"
            color={Colors.darkNeutral}
            style={styles.activityIndicator}
          />
        )}
        {children}
      </View>
    </Pressable>
  );
};

export default DECBaseButton;

const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    backgroundColor: Colors.backgroundPrimary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  pressed: {
    opacity: 0.7,
  },
  success: {
    backgroundColor: Colors.success,
  },
  error: {
    backgroundColor: Colors.error,
  },

  content: {
    // flex: 1,
    // height: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: UI.sizes.padding,
    paddingVertical: (UI.sizes.padding * 1.618) / 2.618,
  },
  activityIndicator: {
    marginRight: 10,
  },
});
