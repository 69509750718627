import { Platform } from "react-native";

export const API_BASE_URL = __DEV__
  ? Platform.OS === "android"
    ? "http://192.168.1.129:3000/api/v1"
    : "http://localhost:3000/api/v1"
  : "https://app.armesimunitii.ro/api/v1";

export const WEBSOCKET_BASE_URL = __DEV__
  ? "ws://localhost:3000/cable"
  : "wss://armesimunitii.ro/cable";

export const questionsCategories = [
  "I - Dispoziții generale privind regimul armelor și munițiilor",
  "II - Condiții privind procurarea, deținerea, portul și folosirea armelor letale de către persoanele fizice",
  "III - Condiții privind procurarea, deținerea, portul și folosirea armelor neletale de către persoanele fizice",
  "IV - Condițiile de păstrare, port și folosire a armelor letale/neletale de către persoanele fizice",
  "V - Regimul permisului de armă și al pașaportului european pt. arme de foc. Regimul călătoriilor in state terțe cu armele letale și muniția corespunzătoare de către cetățenii români",
  "VI - Contravenții și infracțiuni la regimul armelor și munițiilor",
  "VII - Acordarea primului ajutor in cazul plăgilor prin împușcare",
];

export const UI = {
  sizes: {
    borderRadius: 6,
    borderWidth: 1,
    padding: 16,
    margin: 16,
    shadowOffset: { width: 0, height: 6 },
  },
  font: {
    weight: {
      thin: "100" as "100",
      extraLight: "200" as "200",
      light: "300" as "300",
      regular: "400" as "400",
      medium: "500" as "500",
      semiBold: "600" as "600",
      bold: "700" as "700",
      extraBold: "800" as "800",
      black: "900" as "900",
    },
  },
};
