import "react-native-gesture-handler";
import "@/global.css";
import { StatusBar } from "expo-status-bar";
import { Platform, SafeAreaView, StyleSheet, Text, View } from "react-native";
import AppContent from "./src/AppContent";
import * as SplashScreen from "expo-splash-screen";
import { Colors, ColorsPalette } from "@config/colors";
import { Provider } from "react-redux";
import store from "@store/index";
import { NativeBaseProvider, extendTheme } from "native-base";

SplashScreen.preventAutoHideAsync();
const config = {
  useSystemColorMode: false,
  initialColorMode: "dark",
};

const customTheme = extendTheme({ config, colors: ColorsPalette });

function App() {
  return (
    <Provider store={store}>
      <NativeBaseProvider theme={customTheme}>
        <View style={styles.container}>
          <StatusBar style="light" />
          <AppContent />
        </View>
      </NativeBaseProvider>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundSecondary,
  },
});

export default App;
