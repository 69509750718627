import { logout } from "@Core/Auth/store/auth/authThunks";
import { UserDetails } from "./types";
import { userDetailsApi } from "./userDetailsApi";
import { api } from "..";

export const onUpdateUserDetailsQueryStarted = async (
  details: UserDetails,
  { dispatch, queryFulfilled }
) => {
  const patchResult = dispatch(
    userDetailsApi.util.updateQueryData(
      "getUserDetails",
      undefined,
      (detailsDraft) => {
        Object.assign(detailsDraft, { ...detailsDraft, ...details });
      }
    )
  );

  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResult.undo();
  }
};

export const onDeleteUserQueryStarted = async (
  _,
  { dispatch, queryFulfilled }
) => {
  try {
    await queryFulfilled;
    dispatch(api.util.resetApiState());
    dispatch(logout());
  } catch (error: any) {}
};
