import React from "react";
import LogoTitle from "@components/UI/LogoTitle";
import HomeScreen from "@screens/student/HomeScreen";
import { Colors } from "@config/colors";
import { StudentDrawerParamList } from "@navigation/types";
import { useGetUserDetailsQuery } from "@services/api/userDetails/userDetailsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { createDrawerNavigator } from "@react-navigation/drawer";
import CustomDrawerContent from "./CustomDrawerContent";
import TestScreen from "@screens/student/TestScreen/TestScreen";
import { useAppSelector } from "@store/hooks";
import StatisticsScreen from "@screens/student/StatisticsScreen/StatisticsScreen";
import LearningMediumStackNavigator from "./LearningMediums/LearningMediumsStackNavigator";
import TestHistoryScreen from "@screens/student/TestHistoryScreen";
import { useGetStudentRandomTestsQuery } from "@services/api/studentRandomTest/studentRandomTestsApi";
import { Image, StyleSheet } from "react-native";
import SettingsScreen from "@screens/student/SettingsScreen";

const DrawerNavigator = createDrawerNavigator<StudentDrawerParamList>();

const StudentDrawerNavigator: React.FC<{}> = (props) => {
  const isAuthenticated = useAppSelector((state) => state.auth.auth.token);
  const { data: userData, isLoading: isLoadingUserData } =
    useGetUserDetailsQuery(undefined, { skip: !isAuthenticated });

  const { data: tests, isLoading: isLoadingTests } =
    useGetStudentRandomTestsQuery(undefined, { skip: !isAuthenticated });

  if (isLoadingTests || isLoadingUserData) {
    return <LoadingOverlay />;
  }

  return (
    <DrawerNavigator.Navigator
      initialRouteName={__DEV__ ? "Home" : "Home"}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.backgroundSecondary,
          borderBottomWidth: 0,
          // height: 80,
        },
        headerTintColor: "#fff",
        headerTitleStyle: {
          fontWeight: "bold",
        },
        headerTitle: (navigation) => <LogoTitle />,
      }}
    >
      <DrawerNavigator.Screen
        name="Home"
        component={HomeScreen}
        options={{
          drawerLabel: "Pagina principala",
          drawerIcon: ({ focused, size, color }) => {
            return (
              <Image
                source={
                  focused
                    ? require("@assets/icons/drawer/home_outline_primary.png")
                    : require("@assets/icons/drawer/home_outline_light.png")
                }
                style={{ height: size, width: size }}
              />
            );
          },
        }}
      />
      <DrawerNavigator.Screen
        name="Test"
        component={TestScreen}
        options={{
          drawerItemStyle: { height: 0 }, // Hide the drawer item
          drawerLabel: () => null, // Optional: Ensures no label is rendered
          // drawerIcon: ({ focused, size, color }) => {
          //   return (
          //     <Image
          //       source={
          //         focused
          //           ? require("@assets/icons/drawer/test_primary.png")
          //           : require("@assets/icons/drawer/test_light.png")
          //       }
          //       style={{ height: size, width: size }}
          //     />
          //   );
          // },
        }}
      />
      <DrawerNavigator.Screen
        name="LearningMediums"
        component={LearningMediumStackNavigator}
        options={{
          drawerLabel: "Mediu de invatare",
          headerShown: false,
          drawerIcon: ({ focused, size, color }) => {
            return (
              <Image
                source={
                  focused
                    ? require("@assets/icons/drawer/learning_primary.png")
                    : require("@assets/icons/drawer/learning_light.png")
                }
                style={{ height: size, width: size }}
              />
            );
          },
        }}
      />
      {tests?.length >= 5 && (
        <DrawerNavigator.Screen
          name="History"
          component={TestHistoryScreen}
          options={{
            drawerLabel: "Istoric",
            drawerIcon: ({ focused, size, color }) => {
              return (
                <Image
                  source={
                    focused
                      ? require("@assets/icons/drawer/history_primary.png")
                      : require("@assets/icons/drawer/history_light.png")
                  }
                  style={{ height: size, width: size }}
                />
              );
            },
          }}
        />
      )}
      {tests?.length >= 5 && (
        <DrawerNavigator.Screen
          name="Statistics"
          component={StatisticsScreen}
          options={{
            drawerLabel: "Statistici",
            drawerIcon: ({ focused, size, color }) => {
              return (
                <Image
                  source={
                    focused
                      ? require("@assets/icons/drawer/statistics_primary.png")
                      : require("@assets/icons/drawer/statistics_light.png")
                  }
                  style={{ height: size, width: size }}
                />
              );
            },
          }}
        />
      )}
      <DrawerNavigator.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          drawerLabel: "Setări",
          drawerIcon: ({ focused, size, color }) => {
            return (
              <Image
                source={
                  focused
                    ? require("@assets/icons/drawer/settings_primary.png")
                    : require("@assets/icons/drawer/settings_light.png")
                }
                style={{ height: size, width: size }}
              />
            );
          },
        }}
      />
    </DrawerNavigator.Navigator>
  );
};

const styles = StyleSheet.create({
  drawerIcon: {
    height: 12,
    width: 12,
  },
});

export default StudentDrawerNavigator;
