import React, { useState } from "react";
import { View, StyleSheet, Text } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StudentDrawerParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import { UI } from "@config/constants";
import SimpleConfirmationModal from "@Core/UI/components/Modals/SimpleConfirmationModal";
import { useDeleteUserMutation } from "@services/api/userDetails/userDetailsApi";
import DeleteAccount from "./DeleteAccount";
import ResetCategoryProgressSection from "./ResetCategoryProgressSection";
import Section from "./Section";

type Props = NativeStackScreenProps<StudentDrawerParamList, "History">;

const SettingsScreen: React.FC<Props> = (props) => {
  // const { navigation } = props;

  return (
    <View style={styles.screen}>
      <Section title="Mediu de invatare">
        <ResetCategoryProgressSection />
      </Section>
      <Section title="Cont" borderColor={Colors.error}>
        <DeleteAccount />
      </Section>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
    paddingTop: 64,
  },
});

export default SettingsScreen;
