import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import SimpleConfirmationModal from "@Core/UI/components/Modals/SimpleConfirmationModal";
import { useResetStudentCategoryProgressesMutation } from "@services/api/studentCategoryProgresses/studentCategoryProgressesApi";
import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = {};

const ResetCategoryProgressSection: React.FC<Props> = (props) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [resetStudentCategoryProgresses, { isLoading, isError, isSuccess }] =
    useResetStudentCategoryProgressesMutation();

  const handleConfirmClick = () => {
    resetStudentCategoryProgresses();
    setConfirmModalOpen(false);
  };

  return (
    <>
      <SimpleConfirmationModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        isLoading={isLoading}
        onConfirm={handleConfirmClick}
        headerText="Sigur resetezi progresul?"
        bodyContent={
          <View>
            <Text style={styles.lightText}>
              Această acțiune este ireversibilă și permanentă.
            </Text>
            <Text style={styles.lightText}>
              Resetarea progresului presupune începerea de la zero a mediului de
              învățare.
            </Text>
          </View>
        }
        confirmButtonText="Șterge contul permanent"
        cancelButtonText="Anulează"
        confirmButtonColor={Colors.warning}
        confirmButtonTextColor={Colors.lightNeutral}
      />
      <DECTextButton
        title="Resetează mediul de învățare"
        textStyle={{ color: Colors.lightNeutral }}
        containerStyle={{
          backgroundColor: Colors.warning,
          marginHorizontal: UI.sizes.margin,
        }}
        onPress={() => setConfirmModalOpen(true)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  lightText: {
    color: Colors.darkNeutral,
  },
});

export default ResetCategoryProgressSection;
