import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React, { useState } from "react";
import { View, Text, Image, StyleSheet, Pressable } from "react-native";

type Props = {
  error?: any;
};

const ErrorNotification: React.FC<Props> = (props) => {
  const { error } = props;

  const [isErrorDetailsOpen, setIsErrorDetailsOpen] = useState(false);

  const handleSeeMorePress = () => {
    setIsErrorDetailsOpen((prev) => !prev);
  };

  return (
    <View style={styles.component}>
      <Image source={require("@assets/icons/error.png")} style={styles.image} />
      <Text style={styles.label}>Ooops! A aparut o eroare...</Text>
      {error && (
        <View>
          <Pressable onPress={handleSeeMorePress}>
            <Text style={styles.smallLabel}>
              {" "}
              Vezi mai {isErrorDetailsOpen ? "putin" : "mult"} :
            </Text>
          </Pressable>
          <Text style={styles.smallLabel}>{JSON.stringify(error)}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: { height: 128, width: 128 },
  label: {
    color: Colors.darkNeutral,
    fontSize: 24,
    fontWeight: UI.font.weight.semiBold,
  },
  smallLabel: {
    fontSize: 12,
    fontWeight: UI.font.weight.light,
    color: Colors.darkNeutral,
  },
});

export default ErrorNotification;
