import React from "react";
import { Pressable, Text, StyleSheet } from "react-native";
import { Colors } from "../../../config/colors";
import { UI } from "../../../config/constants";

type Props = {
  id: number;
  body: string;
  isCorrect: boolean;
  index: number;
  isShowingIfCorrect: boolean;
  isSelected: boolean;
  onPress: (id: number) => void;
};

const AnswerButton: React.FC<Props> = (props) => {
  const {
    id,
    body,
    isCorrect,
    index,
    isShowingIfCorrect,
    isSelected,
    onPress,
  } = props;

  const handlePress = () => {
    onPress(id);
  };
  const isShowingIfCorrectStyle = {
    backgroundColor: isCorrect ? Colors.success : Colors.error,
    borderColor: isSelected ? Colors.accent : isCorrect && Colors.success,
    color: "#FFF",
  };
  const selectedStyle = {
    borderColor: Colors.accent,
    borderWidth: 1,
  };

  const dynamicTextStyle = {
    color: isShowingIfCorrect ? Colors.lightNeutral : Colors.darkNeutral,
  };

  return (
    <Pressable
      style={[
        styles.component,
        isSelected && selectedStyle,
        isShowingIfCorrect && isShowingIfCorrectStyle,
      ]}
      onPress={handlePress}
    >
      <Text style={[styles.text, dynamicTextStyle]}>
        {["a", "b", "c"][index]}
        {". "}
        {/* {__DEV__ && true && isCorrect && "Correct"} */}
        {body}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {
    padding: 16,
    backgroundColor: Colors.backgroundSecondary,
    marginBottom: UI.sizes.margin * 2,
    borderRadius: UI.sizes.borderRadius,
    borderWidth: 1,
    borderColor: Colors.backgroundSecondary,
    width: "100%",
    shadowColor: "black",
    shadowOffset: UI.sizes.shadowOffset,
    shadowOpacity: 0.2,
    shadowRadius: UI.sizes.borderRadius,
  },
  text: {
    color: Colors.darkNeutral,
  },
});

export default AnswerButton;
