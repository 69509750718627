import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useRoute } from "@react-navigation/native";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { Student } from "@services/api/students/type";
import { formatRomanianNumber } from "@Core/Auth/utils/phoneNumber";
import DECTrashButton from "@Core/UI/components/Buttons/DECTrashButton";
import { useRemoveStudentMutation } from "@services/api/students/studentsApi";
import { Box, Modal, VStack } from "native-base";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import StudentTestsCount from "./StudentTestsCount";

type Props = {
  student: Student;
  first: boolean;
  last: boolean;
  groupId?: number;
};

const StudentListItem: React.FC<Props> = (props) => {
  const { student, first, last, groupId } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [
    removeStudent,
    {
      isLoading: isLoadingRemove,
      isError: isErrorRemove,
      isSuccess: isSuccessRemove,
    },
  ] = useRemoveStudentMutation();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onConfirm = () => {
    removeStudent(student);
    onClose();
  };
  return (
    <>
      <View
        style={[
          styles.component,
          first ? styles.firstItem : last ? styles.lastItem : {},
        ]}
      >
        <View style={[styles.content, last ? { borderBottomWidth: 0 } : {}]}>
          <View style={styles.fieldContainer}>
            <Text
              style={[
                styles.text,
                styles.nameText,
                isHovered && styles.highlightedTextL,
              ]}
            >
              {student.firstName + " " + student.lastName}
            </Text>
          </View>
          <View style={styles.fieldContainer}>
            <Text
              style={[
                styles.text,
                styles.nameText,
                isHovered && styles.highlightedTextL,
              ]}
            >
              {formatRomanianNumber(student.phoneNumber)}
            </Text>
          </View>
          {!groupId && (
            <View style={styles.fieldContainer}>
              <Text
                style={[
                  styles.text,
                  styles.nameText,
                  isHovered && styles.highlightedTextL,
                ]}
              >
                {"Grupa: " + student.groupName}
              </Text>
            </View>
          )}
          <View style={styles.fieldContainer}>
            <StudentTestsCount
              passed={student.randomTestsPassed}
              failed={student.randomTestsFailed}
            />
          </View>
          <DECTrashButton
            size="sm"
            onPress={() => setIsModalOpen(true)}
            isLoading={isLoadingRemove}
            isSuccess={isSuccessRemove}
            isError={isErrorRemove}
          />
        </View>
      </View>
      <Modal isOpen={isModalOpen} onClose={onClose}>
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>Esti sigur?</Modal.Header>
          <Modal.Body>
            <VStack space={3}>
              <Text style={styles.modalText}>
                Sigur vrei sa opresti accesul studentului{" "}
                <Text style={styles.modalTextHighlighted}>
                  {student.lastName} {student.firstName}
                </Text>
                ?
              </Text>
              <Text style={styles.modalText}>
                Aceasta actiune este ireversibila si vei pierde invitatia
                folosita pentru acest student
              </Text>
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Box marginRight={12}>
              <DECTextButton title="Anulează" onPress={onClose} />
            </Box>
            <DECTextButton
              title="Sigur"
              onPress={onConfirm}
              containerStyle={{ backgroundColor: Colors.error }}
            />
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  component: {
    width: "100%",
    backgroundColor: Colors.backgroundPrimary,
  },
  firstItem: {
    borderTopRightRadius: UI.sizes.borderRadius,
    borderTopLeftRadius: UI.sizes.borderRadius,
    borderColor: "rgba(255, 255, 255, 0.3)",
  },
  lastItem: {
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 2,
    // paddingVertical: UI.sizes.padding,
    marginHorizontal: UI.sizes.margin,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.1)",
  },
  nameText: {
    fontSize: 14,
  },
  fieldContainer: {
    flex: 1,
    justifyContent: "center",
  },
  text: {
    color: Colors.darkNeutral,
    marginRight: UI.sizes.margin,
  },
  highlightedTextL: {
    color: Colors.accent,
  },
  modalText: {
    color: Colors.darkNeutral,
  },
  modalTextHighlighted: {
    color: Colors.warning,
    fontWeight: UI.font.weight.semiBold,
  },
});

export default StudentListItem;

// import React, { ReactNode, useState } from "react";
// import { View, Text, StyleSheet, Pressable, Image } from "react-native";
// import { UI } from "@config/constants";
// import { Colors } from "@config/colors";
// import SimpleConfirmationModal from "@Core/UI/Modals/SimpleConfirmationModal";
// import { UseMutationStateResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
// import DECTrashButton from "@Core/UI/Buttons/DECTrashButton";
// import { useRemoveStudentMutation } from "@services/api/students/studentsApi";
// import { Student } from "@services/api/students/type";

// type Props = {
//   student: any;
//   last?: boolean;
// };

// const StudentListItem = (props: Props) => {
//   const { student, last } = props;
//   const [isHovered, setIsHovered] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [
//     removeStudent,
//     {
//       isLoading: isLoadingRemove,
//       isError: isErrorRemove,
//       isSuccess: isSuccessRemove,
//     },
//   ] = useRemoveStudentMutation();

//   const { id, ...dataWithoutId } = student;
//   const fields = Object.keys(dataWithoutId);

//   // const handleModalClose = () => {
//   //   setIsModalOpen(false);
//   // };

//   // const handleConfirmDelete = () => {
//   //   removeStudent(student);
//   //   handleModalClose();
//   // };

//   const handleRemove = () => {
//     removeStudent(student);
//   };

//   return (
//     <>
//       <Pressable
//         style={[styles.component, last && styles.lastItem]}
//         // onPress={handleRemove}
//         onHoverIn={() => setIsHovered(true)}
//         onHoverOut={() => setIsHovered(false)}
//       >
//         <View style={[styles.content, last && { borderBottomWidth: 0 }]}>
//           {fields.map((field) => (
//             <Text
//               style={[styles.text, isHovered && styles.highlightedText]}
//               key={field}
//             >
//               {student[field]}
//             </Text>
//           ))}
//           <View>
//             <DECTrashButton
//               onPress={handleRemove}
//               isLoading={isLoadingRemove}
//               isSuccess={isSuccessRemove}
//               isError={isErrorRemove}
//               size="sm"
//             />
//           </View>
//         </View>
//       </Pressable>
//       {/* <SimpleConfirmationModal
//         isOpen={isModalOpen}
//         onConfirm={handleConfirmDelete}
//         onClose={handleModalClose}
//       >
//         <Text style={styles.modalText}>
//           Sigur vrei sa opresti accesul studentului{" "}
//           <Text style={styles.modalTextHighlighted}>
//             {student.firstName} {student.lastName}
//           </Text>
//           ?
//         </Text>
//         <Text style={styles.modalText}>
//           Aceasta actiune este ireversibila si vei pierde invitatia folosita
//           pentru acest student
//         </Text>
//       </SimpleConfirmationModal> */}
//     </>
//   );
// };

// const styles = StyleSheet.create({
//   component: {
//     width: "100%",
//     backgroundColor: Colors.backgroundPrimary,
//   },
//   headerItem: {
//     borderTopRightRadius: UI.sizes.borderRadius,
//     borderTopLeftRadius: UI.sizes.borderRadius,
//     borderColor: "rgba(255, 255, 255, 0.3)",
//     borderBottomWidth: 1,
//   },
//   lastItem: {
//     borderBottomRightRadius: UI.sizes.borderRadius,
//     borderBottomLeftRadius: UI.sizes.borderRadius,
//   },
//   content: {
//     flex: 1,
//     flexDirection: "row",
//     justifyContent: "space-between",
//     paddingVertical: UI.sizes.padding,
//     marginHorizontal: UI.sizes.margin,
//     borderBottomWidth: 1,
//     borderColor: "rgba(255, 255, 255, 0.1)",
//   },
//   centeredContainer: {
//     height: "100%",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   text: {
//     flex: 1,
//     color: Colors.darkNeutral,
//     textAlignVertical: "center",
//     marginRight: UI.sizes.margin,
//   },
//   highlightedText: {
//     color: Colors.accent,
//   },
//   modalText: {
//     color: Colors.darkNeutral,
//   },
//   modalTextHighlighted: {
//     color: Colors.warning,
//     fontWeight: UI.font.weight.semiBold,
//   },
// });

// export default StudentListItem;
