import { Box, Modal, VStack } from "native-base";
import React, { ReactNode } from "react";
import DECTextButton from "../Buttons/DECTextButton";
import { Colors } from "@config/colors";

type Props = {
  isOpen: boolean;
  bodyContent?: ReactNode;
  children?: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  headerText?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  confirmButtonTextColor?: string;
  isLoading?: boolean;
};

const SimpleConfirmationModal: React.FC<Props> = (props) => {
  const {
    bodyContent,
    children,
    headerText,
    cancelButtonText,
    confirmButtonText,
    confirmButtonColor,
    confirmButtonTextColor,

    isOpen,
    onClose,
    onConfirm,
    isLoading,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxWidth="400px">
        <Modal.CloseButton />
        <Modal.Header>{headerText ? headerText : "Esti sigur?"}</Modal.Header>
        <Modal.Body>
          <VStack space={3}>{bodyContent ? bodyContent : children}</VStack>
        </Modal.Body>
        <Modal.Footer>
          <Box marginRight={12}>
            <DECTextButton title="Anulează" onPress={onClose} />
          </Box>
          <DECTextButton
            disabled={isLoading}
            title="Sigur"
            onPress={onConfirm}
            textStyle={
              confirmButtonTextColor ? { color: confirmButtonTextColor } : {}
            }
            containerStyle={{
              backgroundColor: confirmButtonColor
                ? confirmButtonColor
                : Colors.error,
            }}
          />
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default SimpleConfirmationModal;
