import { View, Text, StyleSheet } from "react-native";
import React, { useState } from "react";

import { useDeleteUserMutation } from "@services/api/userDetails/userDetailsApi";

import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import SimpleConfirmationModal from "@Core/UI/components/Modals/SimpleConfirmationModal";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";

const DeleteAccount = () => {
  const [confirmDeleteAccountModalOpen, setConfirmDeleteAccountModalOpen] =
    useState(false);

  const [
    deleteUserAccount,
    { isLoading: isLoadingDeleteUserAccount, isError },
  ] = useDeleteUserMutation();

  const handleDeleteAccountPress = () => {
    deleteUserAccount();
  };
  return (
    <>
      <SimpleConfirmationModal
        isOpen={confirmDeleteAccountModalOpen}
        onClose={() => setConfirmDeleteAccountModalOpen(false)}
        isLoading={isLoadingDeleteUserAccount}
        onConfirm={handleDeleteAccountPress}
        headerText="Sigur ștergi contul?"
        bodyContent={
          <View>
            <Text style={styles.lightText}>
              Această acțiune este ireversibilă și permanentă.
            </Text>
            <Text style={styles.lightText}>
              Ștergerea contului presupune pierderea accesului la întrebările
              școlii în care ești înscris.
            </Text>
          </View>
        }
        confirmButtonText="Șterge contul permanent"
        cancelButtonText="Anulează"
      />
      <DECTextButton
        title="Șterge contul"
        textStyle={{ color: Colors.lightNeutral }}
        containerStyle={{
          backgroundColor: Colors.error,
          marginHorizontal: UI.sizes.margin,
        }}
        onPress={() => setConfirmDeleteAccountModalOpen(true)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  lightText: {
    color: Colors.darkNeutral,
  },
});

export default DeleteAccount;
