import { Colors } from "@config/colors";
import React from "react";
import { View, Text, StyleSheet, ViewStyle, TextStyle } from "react-native";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  borderColor?: string;
  borderRadius?: number;
  borderWidth?: number;
  backgroundColor?: string;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  borderColor = Colors.accent,
  borderRadius = 10,
  borderWidth = 2,
  backgroundColor = Colors.backgroundPrimary,
}) => {
  // Styles that depend on props
  const containerStyle: ViewStyle = {
    borderColor,
    borderRadius,
    borderWidth,
    backgroundColor,
    position: "relative",
    padding: 16, // Adjust padding as needed
    marginBottom: 16,
  };

  const titleContainerStyle: ViewStyle = {
    position: "absolute",
    top: -borderWidth - 8, // Adjust this value to position the title vertically
    left: 10, // Adjust this value to position the title horizontally
    backgroundColor,
    paddingHorizontal: 4,
  };

  const titleTextStyle: TextStyle = {
    fontWeight: "bold",
    color: borderColor,
  };

  return (
    <View style={containerStyle}>
      {/* Content */}
      {children}
      {/* Title */}
      <View style={titleContainerStyle}>
        <Text style={titleTextStyle}>{title}</Text>
      </View>
    </View>
  );
};

export default Section;
